import React from 'react';
import children from '../../images/children.svg';
import tasks from '../../images/tasks.svg';
import prizes from '../../images/prizes.svg';
import settings from '../../images/settings.svg';
import styled from 'styled-components';
import { Link } from '@reach/router';

const MenuWrapper = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  justify-content: flex-start;
  max-width: 280px;
`;

const MenuItem = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  & img {
    width: 100%;
  }
`;

function Menu () {
  return (
    <MenuWrapper>
      <MenuItem>
        <Link to='/children'><img src={children} alt="Children Menu Item" /></Link>
      </MenuItem>
      <MenuItem>
        <Link to='/tasks'><img src={tasks} alt="Tasls Menu Item" /></Link>
      </MenuItem>
      <MenuItem>
        <img src={prizes} alt="Prizes Menu Item" />
      </MenuItem>
      <MenuItem>
        <img src={settings} alt="Settings Menu Item" />
      </MenuItem>
    </MenuWrapper>
  );
}

export default Menu;
