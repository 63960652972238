import React from 'react'
import { Col, Row, Container } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

const steps = [
  {
    order: 1,
    title: 'Create their profile',
    text: `Enter basic information about your children, choose an image to represent their profile and you are done

Does your kid have a tablet, computer or smartphone?

You can let them check their tasks there, or do it together on your device. Either way, you retain control over the data at all times and only you view or edit it.`,
    icon: 'fa-child',
    primaryColor: '#BF0060',
    secondaryColor: '#E56E73'
  },
  {
    order: 2,
    icon: 'fa-list',
    title: 'Assign tasks and activities',
    text: `Schoolwork, house chores, extracurricular activities, you name it.

You can pick from our pre-defined list of tasks or create your own. It's always your choice.

You can set a deadline for the tasks and even create reoccurring and scheduled tasks.`,
    primaryColor: '#0FA2E2',
    secondaryColor: '#0E84B7'
},
  {
    order: 3,
    icon: 'fa-star',
    title: 'Select their rewards',
    text: `Each task can have points assigned to it. Upon completing tasks, your child accumulates those points.

The amount can be associated with a deadline or simply to the completion of the task.

We have many rewards they can select from when they have enough points. You can also create your custom rewards.`,
    primaryColor: '#BF0060',
    secondaryColor: '#E56E73'
  },
  {
    order: 4,
    icon: 'fa-check',
    title: 'Follow up and confirm',
    text: `Following up with the tasks can be a fun and bonding activity for you and your children.

You confirm the completion of their tasks so they are rewarded and also review their progress and what prizes they can claim.

Enjoy the moment to help your children learn that their hard work pays off.`,
    primaryColor: '#0FA2E2',
    secondaryColor: '#0E84B7'
  }

]

const ColoredSquare = styled.div`
    color: ${props => props.color || "white"};
    background-color: ${props => props.bg || "white"};
    border-radius: 5px;
    left: ${props => props.align === 'left' ? 0 : 'auto' };
    right: ${props => props.align === 'right' ? 0 : 'auto' };
    top: ${props => props.align === 'left' ? 0 : 'auto' };
    bottom: ${props => props.align === 'right' ? 0 : 'auto' };
    z-index: ${props => props.align === 'left' ? 2 : 1 };
    box-shadow: ${props => props.align === 'left' ? '0 2px 3px rgba(0,0,0,.4)' : 'none' }; 
    position: absolute;
    display: flex;
    width: 80px;
    height: 80px;
    align-items: center;
    justify-content: center;
    font-size: 3vh;
`;


const StepBlock = props => {
  const { title, primaryColor, secondaryColor, text, icon, order } = props;
  const iconClass = 'fa ' + icon;
  return (
    <Col md={6} xs={12} className="step">
      <Row>
        <Col xs={4} md={2}>
          <Row className="iconBox">
            <ColoredSquare bg={primaryColor} align={'left'}>{order}</ColoredSquare>
            <ColoredSquare bg={secondaryColor} align={'right'}><i className={iconClass}></i></ColoredSquare>
          </Row>
        </Col>
        <Col xs={8} md={10}>
          <h3 className="yanone" style={{color: secondaryColor}}>{title}</h3>
          <ReactMarkdown source={text}/>
        </Col>
      </Row>
    </Col>
  )
};


function HowItWorks() {
  return (
    <section className="section how-it-works">
      <Container>
          <Container fluid>
            <Row>
              <Col><h2 className="yanone">How does it work?</h2></Col>
            </Row>
            <Row>
              {steps.map(step => (
                <StepBlock key={'step_' + step.order} order={step.order} title={step.title} text={step.text} primaryColor={step.primaryColor} secondaryColor={step.secondaryColor} icon={step.icon} />))}
            </Row>
          </Container>
      </Container>
      </section>
  )
}

export default HowItWorks
