import React from 'react';
import image from '../images/screens_pink.jpg';
import vertical from '../images/screens_pink_vertical.jpg';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';

const ScreenshotWrapper = styled.section`
  background-color: #bf0060;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-top: 8vh;

  & img {
    max-width: 100%;
  }
`;

function ScreenShots () {
  return (
    <ScreenshotWrapper>
      <Container>
        <Row>
          <Col><h2 className="yanone">Screenshots</h2></Col>
        </Row>
        <Row>
          <Col>
            <picture>
              <source media="(max-width: 799px)" srcSet={vertical} />
              <source media="(min-width: 800px)" srcSet={image} />
              <img src={image} alt="stacked screnshots of the app"/>
            </picture>
          </Col>
        </Row>
      </Container>
    </ScreenshotWrapper>
  );
}

export default ScreenShots;
