import React from 'react';
import './App.scss';
import logo from '../images/logo.svg';
import { Link } from '@reach/router';
import Menu from '../components/menu/Menu';

function App() {
  return (
    <div className="App">
      <div className="app-standby">
        <img src={logo} className="egga-logo" alt="Egga logo"/>
        The app will be available in a bit. Stay tuned!
        <Link to="/">Home</Link>
        <Menu />
      </div>
    </div>
  );
}

export default App;
