import React, { useState, useEffect, useRef } from 'react'
import logo from '../../images/logo.svg';
import { Link } from '@reach/router';
import styled from 'styled-components';
import { Container, Row, Col, Nav } from 'react-bootstrap';

const HeaderWrapper = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-height: 80px;
  align-items: center;
  padding: 20px;
  margin-bottom: 65px;
  z-index: 3;

  & .logo {
    max-height: 80px;
    width: 90px;
  }

  & a {
    color: #ffffff;
    text-decoration: none;
    font-size: 3vh;
  }
`;

function Header() {
  const [scrollTop, setScrollTop] = useState(0);
  const [scrolling, setScrolling] = useState(false);
  const [height, setHeight] = useState(0);
  const refHeader = useRef();

  useEffect(() => {
    setHeight(refHeader.current.clientHeight)
    const onScroll = e => {
      setScrollTop(e.target.documentElement.scrollTop);
      setScrolling(e.target.documentElement.scrollTop > height);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop, height]);

  const className = scrolling ? 'fixed' : '';
  return (
    <HeaderWrapper className={className} ref={refHeader}>
      <Container>
        <Row>
          <Col className="d-flex align-items-center justify-content-between">
            <Link to="/">
              <img src={logo} className="logo fluid" alt="Logo spelling E g g a" />
            </Link>
            <Nav className="justify-content-end d-flex w-100">
              <a href="https://fb.com/eggaioapp" className="ml-5"><i className="fa fa-facebook-square"></i></a>
              <a href="https://instagram.com/egga.io" className="ml-5"><i className="fa fa-instagram"></i></a>
              <a href="https://twitter.com/egga_io" className="ml-5"><i className="fa fa-twitter"></i></a>
            </Nav>
          </Col>
        </Row>
      </Container>
    </HeaderWrapper>
  )
}

export default Header
