import React, { useState } from 'react'
import styled from 'styled-components';
import media from "styled-media-query";
import { useForm } from 'react-hook-form';
import Header from '../components/header/Header';
import ScreenShots from '../screens/ScreenShots';
import { Container, Col, Row, Form, Alert } from 'react-bootstrap';
import HowItWorks from './HowItWorks';
// import VideoBackgroundContainer from '../components/video-background/VideoBackgroundContainer';

// import girlsLarge from '../images/bg/girls-hugging@large.jpg';
// // import girls1080 from '../images/bg/girls-hugging@1080.jpg';
// import girlsvertical from '../images/bg/2girls-vertical@1080.jpg';
// import boylarge from '../images/bg/boy@large.jpg';
// import boyvertical from '../images/bg/boy-vertical@1080.jpg';
// import familyVertical from '../images/bg/family-vertical@1080.jpg';
// import family1080 from '../images/bg/family@1080.jpg';

import footerbg from '../images/bg/footer-bg.svg';

const JumboTronHome = styled.section`
   ${media.lessThan("medium")`
    background-image: url(${props => props.bgImage}_vertical.jpg);
    position: relative;

    & video {
      position: absolute;
      left: 0;
      top: 0;
      width: 100vw;
    }
  `}

  ${media.between("medium", "large")`
    background-image: url(${props => props.bgImage}.jpg);
  `}

  ${media.greaterThan("large")`
    background-image: url(${props => props.bgImage}.jpg);
  `}
`;

// const GetStartedButton = styled.button`
//   border: none;
//   background-color: #BF0060;
//   font-size: 3vh;
//   font-family: 'Yanone Kaffeesatz', sans-serif;
//   color: #fff;
//   padding: 1vh 3vw;
//   border-radius: 5px;
// `;

const SubmitButton = styled.button`
  border: none;
  background-color: #0FA2E2;
  font-size: 3vh;
  font-family: 'Yanone Kaffeesatz', sans-serif;
  color: #fff;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  display: block;
`;

const Footer = styled.footer`
    background-image: url(${footerbg});
    min-height 43vh;
    background-color: #2B3C4E;
    padding-top: 15vh;
    display: flex;
    align-items: center;
    
    @media (max-width: 768px) {
      padding-top: 5vh;
    }
    & a {
      font-size: 3vh;
      color: #ffffff;
    }
`;

const Overlay = styled.div`
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.3);
    top: 0;
    left: 0;
`;


export const Home = () => {
  const bgImage = `/img/bg${Math.ceil(Math.random() * 14)}`;
  return (
    <div className="home">

      <JumboTronHome className='screen' bgImage={bgImage}>
        <Overlay/>
          <Header />
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <h1 className="yanone">Teach your children their efforts pay off</h1>
                <p className="home-text">
                  Egga helps children understand that hard work, planning, and discipline will be crucial in achieving what they desire. At the same time, it helps you bond with them and encourage them towards the completion of the tasks they usually slack on tackling.
              </p>
                {/* <GetStartedButton>Get Started</GetStartedButton> */}
              </Col>
            </Row>
          </Container>
        </JumboTronHome>    


      <ScreenShots />
      <HowItWorks />
      <Footer>
        <Container>
          <Row>
            <Col xs={12} md={6} className="mt-3">
              <a href="https://fb.com/eggaioapp" className="mr-5"><i className="fa fa-facebook-square"></i></a>
              <a href="https://instagram.com/egga.io" className="mr-5"><i className="fa fa-instagram"></i></a>
              <a href="https://twitter.com/egga_io" className="mr-5"><i className="fa fa-twitter"></i></a>
            </Col>
            <Col xs={12} md={6} className="mt-3">
             <ContactForm/>
            </Col>
          </Row>
        </Container>
      </Footer>
    </div>
  )
}

export default Home;

const ContactForm = () => {
  const [sending, setSending] = useState(false); 
  const [result, setResult] = useState(null);
  const { handleSubmit, register, errors } = useForm();
  let resultMessage = null;
  if (result && result.status === 'error') {
      resultMessage = (<Alert variant="danger" onClose={() => setResult(null)} dismissible>
        <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
        <p>
            Could not send your message, please try again!
        </p>
      </Alert>);
    } else {
      if (result && result.status === 'success') {
        resultMessage = (<Alert variant="success" onClose={() => setResult(null)} dismissible>
            <Alert.Heading>Message Sent!</Alert.Heading>
            <p>
               We will be in touch soon.
            </p>
          </Alert>);
      }
    }

  const onSubmit = values => {
    setSending(true)
    const url = "https://api.egga.io/message";
    fetch(url, {
      method: "POST",
      headers: {
        'X-Api-Key': 'Aqa5Z4jjkX1PebQ0m2kAT3PNQNDAVc0q5xyu6woe'
      },
      body: JSON.stringify(values)
    }).then(
      response => response.json() // .json(), etc.
    ).then(
      html => {
        setResult({ status: 'success', message: 'Message sent' });
        setSending(false);
      }
    ).catch(e => {
      setResult({ status: 'error', message: 'Could not send the message' });
      setSending(false);
    }) 

  }

  if (resultMessage) {
    return (<><h3 className="yanone">Contact</h3> {resultMessage} </>);
  } else {
  
  return (
    <>
       <h3 className="yanone">Contact</h3>
      <Form onSubmit={handleSubmit(onSubmit)} disabled={sending}>
        <Row>
          <Col>
              <Form.Control
                disabled={sending}
                placeholder="name"
                name="name"
                ref={register({
                  validate: value => value !== "admin" || "Nice try!"
                })}
              />
              {errors.name && errors.name.message}
          </Col>
          <Col>
            <Form.Control
              disabled={sending}
              placeholder="email"
              name="email"
              ref={register({
                required: "Required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "invalid email address"
                }
              })}
            />
            {errors.email && errors.email.message}
          </Col>
        </Row>
        <Row className="mt-2">
          <Col xs={9}>
            <Form.Control as="textarea" name="message" ref={register} rows="3" placeholder="Message" disabled={sending}/>
          </Col>
          <Col xs={3}>
              <SubmitButton disabled={sending}>Send</SubmitButton>
          </Col>
        </Row>
      </Form>
      </>
  );
  };
};